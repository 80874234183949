import { map } from 'rxjs';
import type { Observable } from 'rxjs';
import { singleton, inject } from 'tsyringe';
import { cleanMaybe } from '@oms/shared/util';
import type { Optional } from '@oms/shared/util-types';
import { AUTH_EVENT_ACTION } from '@app/common/auth/auth.contracts';
import { AuthSignal } from '@app/data-access/memory/auth.signal';
import type { ParsedIdentityToken } from '@app/common/auth/keycloak.types';

@singleton()
export class AuthService {
  constructor(@inject(AuthSignal) protected authState: AuthSignal) {}

  /**
   * Observable of the auth state
   */
  public get $() {
    return this.authState.signal.$;
  }

  /**
   * Current auth state
   */
  public get state() {
    return this.authState.signal.get();
  }

  /**
   * Broadcasts an event to log the user out.
   * This will trigger the keycloak logout process in the auth window actor
   */
  public logout() {
    this.authState.action$.next(AUTH_EVENT_ACTION.LOGOUT);
  }

  /**
   * Get the user ID of the currently logged in user
   *
   * @returns The user ID of the currently logged in user (null if not logged in)
   */
  public getUserId(): string | null {
    // Get our current keycloak instance's parsed token. The user ID is the "sub" prop. See:
    // https://www.keycloak.org/docs/latest/server_development/index.html#_action_token_anatomy
    return this.authState.signal.get()?.tokenParsed?.sub || null;
  }

  public get currentUser(): Optional<ParsedIdentityToken> {
    return cleanMaybe(this.state.tokenParsed);
  }

  public get currentUser$(): Observable<Optional<ParsedIdentityToken>> {
    return this.$.pipe(map(({ tokenParsed }) => cleanMaybe(tokenParsed)));
  }
}
