import { combineLatest, filter, map, distinctUntilChanged } from 'rxjs';
import type { Subscription, Observable } from 'rxjs';
import { useObservableState } from 'observable-hooks';
import type { AuthClientState } from '@app/common/auth/keycloak.types';
import type { DataAccessState } from '@app/common/data-access/data-access.contracts';
import { APP_STATE_TYPE, DEFAULT_APP_STATE } from '@app/common/app/app.contracts';
import type { AppState as AppStateType } from '@app/common/app/app.contracts';
import { AuthSignal } from './auth.signal';
import { DataAccessSignal } from './data-access.signal';
import { SyncronisationSignal, SyncronisationState } from './syncronisation.signal';
import { createLogger } from '@oms/shared/util';
import { inject, singleton } from 'tsyringe';
import type { Disposable } from 'tsyringe';
import { useService } from '@oms/frontend-foundation';

const l = createLogger({
  label: 'app.stream'
});

/**
 * AppState
 *
 * Combines AuthSignal, DataAccessSignal, and SyncronisationSignal to determine the current state of the application
 *
 * @usage
 * ```ts
 * const appState = container.resolve(AppState);
 * const subscription = appState.$.subscribe((state) => {
 *  l.info('App state changed', state);
 * });
 * ```
 *
 * @usage
 * ```ts
 * constructor(@inject(AppState) private appState: AppState) {
 *  const subscription = this.appState.$.subscribe((state) => {
 *    l.info('App state changed', state);
 *  });
 *
 * }
 */
@singleton()
export class AppState implements Disposable {
  private subscriptions: Subscription[] = [];
  public $: Observable<AppStateType>;

  constructor(
    @inject(AuthSignal) private authState: AuthSignal,
    @inject(DataAccessSignal) private dataAccessSignal: DataAccessSignal,
    @inject(SyncronisationSignal) private syncronisationSignal: SyncronisationSignal
  ) {
    this.$ = combineLatest([
      this.authState.signal.$,
      this.dataAccessSignal.signal.$,
      this.syncronisationSignal.signal.$
    ]).pipe(
      map(([auth, dataAccess, syncronisation]) => {
        const appState: AppStateType = {
          state: this.getAppStateType(auth, dataAccess, syncronisation),
          auth,
          dataAccess,
          user: auth.tokenParsed ?? null,
          syncronisation
        };
        return appState;
      }),
      distinctUntilChanged()
    );

    // Debugging purposes (long-lived subscriptions)
    this.subscriptions.push(
      this.dataAccessSignal.signal.$.subscribe((state) => {
        l.debug('Data access state changed', state);
      })
    );

    this.subscriptions.push(
      authState.signal.$.subscribe((state) => {
        l.debug('Auth state changed', state);
      })
    );

    this.subscriptions.push(
      this.syncronisationSignal.signal.$.subscribe((state) => {
        l.debug('Syncronisation state changed', state);
      })
    );

    this.subscriptions.push(
      this.$.subscribe((state) => {
        l.debug('App state changed', state.state, state);
      })
    );
  }

  public get DEFAULT_STATE() {
    return DEFAULT_APP_STATE;
  }

  public get ready$() {
    return this.$.pipe(filter((state) => state.state === 'Ready'));
  }

  public dispose() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private getAppStateType(
    auth: AuthClientState,
    dataAccess: DataAccessState,
    syncronisation: SyncronisationState
  ) {
    const isAuthenticating = !auth.isAuthenticated && !auth.isReady;
    const isAuthorized = auth.isAuthenticated && auth.isReady;
    const isUnauthorized = !auth.isAuthenticated && auth.isReady;
    const isConnecting = !dataAccess.isConnected && !dataAccess.isReady;
    const isConnected = dataAccess.isConnected && dataAccess.isReady;
    const isDisconnected = !dataAccess.isConnected && dataAccess.isReady;
    const isSyncronising = syncronisation.isSyncronising;
    const isSyncronised = syncronisation.isSyncronised;

    switch (true) {
      case isAuthenticating:
        return APP_STATE_TYPE.AUTHENTICATING;
      case isUnauthorized:
        return APP_STATE_TYPE.UNAUTHORIZED;
      case isConnecting:
        return APP_STATE_TYPE.DATA_ACCESS_CONNECTING;
      case isSyncronising: {
        return APP_STATE_TYPE.SYNCRONISING;
      }
      case isAuthorized && isConnected && isSyncronised:
        return APP_STATE_TYPE.READY;
      case isConnected:
        return APP_STATE_TYPE.DATA_ACCESS_CONNECTED;
      case isDisconnected:
        return APP_STATE_TYPE.DATA_ACCESS_DISCONNECTED;
      default:
        return APP_STATE_TYPE.IDLE;
    }
  }
}

/**
 * Subscribe to the AppState stream
 *
 * @usage
 * ```ts
 * const appState = useAppStateStream();
 * l.info('App state changed', appState);
 * ```
 *
 * @returns AppState stream
 */
export function useAppStateStream(): AppStateType {
  const service = useService(AppState);
  return useObservableState(service.$, service.DEFAULT_STATE);
}
