import { Fragment } from 'react';
import type { RefObject, FC } from 'react';
import { Flex } from '@oms/shared-frontend/ui-design-system';
import { DepthGrid } from './depth-grid/depth.grid';
import { useMontageInstrumentDetails } from '@app/data-access/services/trading/montage/hooks/use-montage-instrument-details.hook';
import { useSyncDepthScroll } from './use-sync-depth-scroll';
import { useLevel2Data } from './depth-grid/hooks/use-level2-data.hook';

interface DepthGridPanesProps {
  bidRef: RefObject<HTMLDivElement>;
  askRef: RefObject<HTMLDivElement>;
}

const DepthGridPanes: FC<DepthGridPanesProps> = ({ bidRef, askRef }) => {
  const level2RawData$ = useLevel2Data();

  return (
    <Fragment>
      <Flex direction="column" sx={{ height: 'full', width: 'full', marginRight: 2 }} ref={bidRef}>
        <DepthGrid level2RawData$={level2RawData$} type="bid" />
      </Flex>
      <Flex direction="column" sx={{ height: 'full', width: 'full' }} ref={askRef}>
        <DepthGrid level2RawData$={level2RawData$} type="ask" />
      </Flex>
    </Fragment>
  );
};

const DepthGridNoInstrumentPlaceholder: FC = () => {
  return (
    <Flex sx={{ height: 'full', width: 'full' }} align="center" justify="center">
      <p>Please select an instrument</p>
    </Flex>
  );
};

export const MontageDepthGrid: FC = () => {
  const [ref, bidRef, askRef] = useSyncDepthScroll();
  const instrumentDetails = useMontageInstrumentDetails();

  return (
    <Flex
      ref={ref}
      direction="row"
      sx={{ height: 'full', width: 'full', overflow: 'hidden', borderRadius: 'base' }}
    >
      {instrumentDetails ? (
        <DepthGridPanes bidRef={bidRef} askRef={askRef} />
      ) : (
        <DepthGridNoInstrumentPlaceholder />
      )}
    </Flex>
  );
};
