import type { MarketDataWidgetProps, MarketDataWidgetType } from './market-data.types';

export const marketDataWidgetTypeMap: Record<
  Exclude<MarketDataWidgetType, 'custom'>,
  Pick<MarketDataWidgetProps, 'gridProps' | 'layout' | 'values' | 'trackableValues' | 'trackableRangeValues'>
> = {
  /**
   * Vertical Grid with 2 cols & 10 rows
   */
  'om-grid': {
    gridProps: {
      columns: 2,
      rows: 10,
      columnGap: 5,
      rowGap: 4
    },
    values: [
      'statusTags',
      'open',
      'prevClose',
      'last',
      'exchange',
      'high',
      'low',
      'close',
      'volume',
      'change',
      'changePercent',
      'limitUp',
      'limitDown',
      'ask',
      'askSize',
      'bid',
      'bidSize',
      'adv',
      'fiftyTwoWeekRange',
      'vwap',
      'shortSellRestricted'
    ],
    trackableValues: ['last', 'bid', 'ask', 'vwap'],
    trackableRangeValues: { bidAsk: ['bid', 'ask'] },
    layout: 'vertical'
  },
  'om-grid-montage-only': {
    gridProps: {
      columns: 2,
      rows: 10,
      columnGap: 5,
      rowGap: 4
    },
    values: [
      'statusTags',
      'open',
      'prevClose',
      'last',
      'exchange',
      'high',
      'low',
      'close',
      'volume',
      'change',
      'changePercent',
      'limitUp',
      'limitDown',
      'ask',
      'askSize',
      'bid',
      'bidSize',
      'adv5day',
      'fiftyTwoWeekRange',
      'vwap',
      'shortSellRestricted'
    ],
    trackableValues: ['last', 'bid', 'ask', 'vwap'],
    trackableRangeValues: { bidAsk: ['bid', 'ask'] },
    layout: 'vertical'
  },
  'om-grid-short': {
    gridProps: {
      columns: 2,
      rows: 3,
      columnGap: 5,
      rowGap: 4
    },
    values: ['bid', 'ask', 'high', 'low', 'volume', 'adv'],
    trackableValues: ['last', 'bid', 'ask'],
    layout: 'vertical'
  },
  'om-grid-short-montage-only': {
    gridProps: {
      columns: 2,
      rows: 3,
      columnGap: 5,
      rowGap: 4
    },
    values: ['bid', 'ask', 'high', 'low', 'volume', 'adv5day'],
    trackableValues: ['last', 'bid', 'ask'],
    layout: 'vertical'
  },
  /**
   * Banner with 1 row and minimal info
   */
  'om-banner': {
    values: [
      { key: 'lastAdvanced', colSpan: 4, displayOverride: { labelMargin: 5 } },
      { key: 'bid', colSpan: 3, displayOverride: { labelMargin: 5 } },
      { key: 'mid', colSpan: 3, displayOverride: { labelMargin: 5 } },
      { key: 'ask', colSpan: 3, displayOverride: { labelMargin: 5 } },
      { key: 'vwap', colSpan: 3, displayOverride: { labelMargin: 5 } },
      { key: 'volume', colSpan: 3, displayOverride: { labelMargin: 5 } },
      { key: 'adv', colSpan: 2, displayOverride: { labelMargin: 5 } },
      { key: 'statusTags', colSpan: 2 },
      { key: 'shortSellRestricted', colSpan: 2 }
    ],
    trackableValues: ['last', 'bid', 'ask', 'vwap'],
    trackableRangeValues: { bidAsk: ['bid', 'ask'] },
    gridProps: {
      columns: 24,
      rows: 1,
      columnGap: 3
    },
    layout: 'horizontal'
  },
  'om-banner-detailed': {
    values: [
      { key: 'open', colSpan: 2 },
      { key: 'prevClose', colSpan: 3 },
      { key: 'last', colSpan: 2 },
      { key: 'changePercent', colSpan: 3 },
      { key: 'highLow', colSpan: 4 },
      { key: 'vwap', colSpan: 3 },
      { key: 'volume', colSpan: 3 },
      { key: 'adv5day', colSpan: 2 },
      { key: 'statusTags', colSpan: 2 }
    ],
    trackableValues: ['last', 'high', 'low', 'vwap'],
    trackableRangeValues: { highLow: ['high', 'low'] },
    gridProps: {
      columns: 24,
      rows: 1
    },
    layout: 'vertical'
  },
  'om-banner-compact': {
    values: [
      { key: 'last', colSpan: 5 },
      { key: 'changePercent', colSpan: 5 },
      { key: 'highLow', colSpan: 6 },
      { key: 'volume', colSpan: 5 },
      { key: 'statusTags', colSpan: 3 }
    ],
    trackableValues: ['last', 'high', 'low'],
    trackableRangeValues: { highLow: ['high', 'low'] },
    gridProps: {
      columns: 24,
      rows: 1
    },
    layout: 'vertical'
  }
};
