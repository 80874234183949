import 'reflect-metadata';
import { POPOVER_COMPONENT, foundationWorkspacePlugin } from '@oms/frontend-foundation';
import type { FoundationFormMappers, AnyRecord, AnyFormBuilder } from '@oms/frontend-foundation';
import { ThemeProvider } from '@oms/shared-frontend/ui-design-system';
import { UUID, createLogger } from '@oms/shared/util';
import { HelmetProvider } from 'react-helmet-async';
import type { Plugin, WidgetActorComponents } from '@valstro/workspace';
import {
  PROCESS_ID,
  browserPlatform,
  isTauri,
  quickTauriWindowsPlugin,
  tauriPlatform
} from '@valstro/workspace';
import {
  ReactWorkspace,
  browserModalWindowActor,
  browserTabWindowActor,
  flexLayoutActor,
  widgetActor,
  tauriWindowActor
} from '@valstro/workspace-react';
import { reactPopoverPlugin } from '@valstro/workspace-react-plugin-popover';
import { authWindowPlugin } from './workspace/plugin-auth/auth-window.plugin';
import { commandPalettePlugin } from './workspace/plugin-command-palette/command-palette.plugin';
import type { AuthClientState } from './common/auth/keycloak.types';
import { COMMON_AUTH_WINDOW } from './common/auth/auth.contracts';
import { AppWorkspace } from './app-config/workspace.config';
import type { AppWindowContext } from './app-config/workspace.config';
import { dataAccessPlugin } from './workspace/plugin-data-access/data-access.plugin';
import { WidgetWrapper } from './widgets/widget.wrapper';
import { SplashScreenContainer } from './containers/splash-screen/splash-screen.container';
import {
  AppWindowWidgetDecoration,
  LauncherWindowWidgetDecoration
} from './widgets/widget.window-decoration.component';
import { setGlobalOptions } from '@valstro/remote-link';
import { initI18n } from './common/i18n/i18n';
import { getEnvVar } from './common/env/env.util';
import { LicenseManager } from '@ag-grid-enterprise/core';
import type { WildcardMockLink } from 'wildcard-mock-link';
import { CommandPaletteService } from './data-access/services/system/command-palette/command-palette.service';
import { registerCommandPaletteDefaults } from './commands/command-palette.registry';
import { COMMON_COMMAND_PALETTE } from './common/command-palette/command-palette.contracts';
import { container as rootContainer } from 'tsyringe';
import type { DependencyContainer } from 'tsyringe';
import { isDesktopLauncherWindow } from './common/launcher/launcher.util';
import { appWindow } from '@tauri-apps/api/window';
import { RootWindowWrapper } from './widgets/root-window.wrapper';
import type { OpenableRegistryDefinition } from './app-config/registry.config';
import { Component } from './common/registry/component.open';
import { Form } from './common/registry/form.open';
import { Dialog } from './common/registry/dialog.open';
import { Layout } from './common/registry/layout.open';
import { ToolbarProvider } from './common/toolbar/toolbar-context';
import { crossPlatformSnapshotsPlugin } from './workspace/plugin-cross-platform-snapshots/cross-platform-snapshots.plugin';
import { launcherAndSplashPlugin } from './workspace/plugin-launcher-and-splash/launcher-and-splash.plugin';
import { marketdataPlugin } from './workspace/plugin-marketdata/marketdata.plugin';
import type { MarketdataPluginOptions } from './workspace/plugin-marketdata/marketdata.plugin';
import { appDatabasePlugin } from './workspace/plugin-app-database/app-database.plugin';
import type { AppDatabaseOptions } from './workspace/plugin-app-database/app-database.plugin';
import { processIdPlugin } from './workspace/plugin-process-id/process-id.plugin';
import { shortcutsPlugin } from './workspace/plugin-shortcuts/shortcuts.plugin';
import { workspaceDebugger } from './workspace/workspace.utils';
import { FlexLayoutWrapper } from './widgets/flexlayout.wrapper';
import { addMeta } from './common/dom/dom';
import { adaptiveLayoutsPlugin } from './workspace/plugin-adaptive-layouts/adaptive-layouts.plugin';
import { notificationsPlugin } from './notifications/notifications.workspace.plugin';
import { syncronisationPlugin } from './workspace/plugin-syncronisation/sycronisation.plugin';
import { InstrumentTrackingPlugin } from './workspace/plugin-instrument-tracking/instrument-tracking.plugin';

// TODO: Default layout & tab screen in browser
// TODO: Translation & translation service / gen

/**
 * Create a logger for the app
 */
export const appLogger = createLogger({ label: 'App' });

/**
 * Set license key for ag-grid
 */
const licenseKey = getEnvVar('NX_CUSTOM_VAR_AG_GRID_LICENSE_KEY');
if (licenseKey && licenseKey.trim().length) {
  LicenseManager.setLicenseKey(licenseKey);
  appLogger.log('[Ag-grid]: Enterprise license initialized');
}

/**
 * Initialize i18n
 */
initI18n();

export interface CreateAppWorkspaceOptions<TForceCompProps extends AnyRecord = AnyRecord> {
  widgetComponentsMap?: WidgetActorComponents<any>;
  dynamicWidgetComponentsMap?: Promise<{ widgetComponentsMap: WidgetActorComponents<any> }>;
  formMappers?: Partial<FoundationFormMappers>;
  forceAuthState?: AuthClientState;
  offlineDb?: AppDatabaseOptions['offline'];
  memoryDb?: AppDatabaseOptions['memory'];
  forceLeader?: boolean;
  forceLoad?: OpenableRegistryDefinition<TForceCompProps>;
  forceLoadProps?: TForceCompProps;
  forceLoadWindowOptions?: Partial<AppWindowContext>;
  plugins?: Plugin[];
  apolloMockLink?: WildcardMockLink;
  apolloMockLinkSetup?: () => WildcardMockLink;
  shouldRegisterCommandPaletteDefaults?: boolean;
  MdgClient?: MarketdataPluginOptions['MdgClient'];
  container?: DependencyContainer;
}

export function createAppWorkspace({
  widgetComponentsMap,
  dynamicWidgetComponentsMap,
  formMappers = {},
  forceAuthState,
  offlineDb,
  memoryDb,
  forceLoad,
  forceLoadProps = {},
  forceLeader,
  plugins = [],
  apolloMockLink,
  shouldRegisterCommandPaletteDefaults = true,
  MdgClient,
  container = rootContainer
}: CreateAppWorkspaceOptions) {
  /**
   * Force the window to be the leader
   * This is useful for testing & running widgets in isolation/storybook
   */
  if (forceLeader) {
    // Ensure no events leave the current process
    // This allows us to have multiple stories/tests running in parallel
    setGlobalOptions({
      broadcastChannelOptions: {
        type: 'simulate'
      }
    });
  }

  /**
   * Create a workspace with plugins
   */
  const workspace = workspaceDebugger(
    new AppWorkspace({
      rootableActorLoadingStrategy: 'localStorage',
      platforms: [browserPlatform, tauriPlatform],
      plugins: [
        processIdPlugin({ container }),
        launcherAndSplashPlugin(),
        appDatabasePlugin({
          offline: offlineDb,
          memory: memoryDb,
          container
        }),
        // TODO: This is temp! ... remove this plugin after feature development is complete!
        InstrumentTrackingPlugin(),
        quickTauriWindowsPlugin({
          bufferSize: 4,
          debug: false,
          defaultTauriOptions: { transparent: true },
          useForApplySnapshot: true,
          ignoredWindowIds: [COMMON_COMMAND_PALETTE.BASE_ID, COMMON_AUTH_WINDOW.ID],
          preloadedQuickWindowIds: [
            'tauri-quick-window-0',
            'tauri-quick-window-1',
            'tauri-quick-window-2',
            'tauri-quick-window-3'
          ]
        }),
        reactPopoverPlugin({
          componentsMap: POPOVER_COMPONENT,
          bufferSize: 2
        }),
        commandPalettePlugin(() => {
          const commandPaletteService = container.resolve(CommandPaletteService);
          return {
            open$: commandPaletteService.open$,
            update$: commandPaletteService.update$,
            close$: commandPaletteService.close$,
            onLeaderReady: async () => {
              await commandPaletteService.initialize();
              if (shouldRegisterCommandPaletteDefaults) {
                return registerCommandPaletteDefaults(container);
              }
            },
            onBrowserChildWindowReady: async () => {
              await commandPaletteService.initialize();
            },
            onReady() {
              addMeta('valstro-command-palette-plugin', 'ready');
            },
            onDispose() {
              commandPaletteService.dispose();
            }
          };
        }),
        authWindowPlugin({
          forceAuthState
        }),
        dataAccessPlugin({ apolloMockLink, container }),
        syncronisationPlugin({ container }),
        marketdataPlugin({ MdgClient, container }),
        crossPlatformSnapshotsPlugin,
        adaptiveLayoutsPlugin(),
        shortcutsPlugin({
          onReady() {
            addMeta('valstro-shortcuts-plugin', 'ready');
          }
        }),
        notificationsPlugin({ container }),
        foundationWorkspacePlugin({ ...formMappers, container }),
        ...plugins
      ],
      actors: [
        browserTabWindowActor({
          wrapperComponent: RootWindowWrapper
        }),
        browserModalWindowActor({
          defaultContext: {
            isDecorated: false
          },
          windowDecorationComponent: AppWindowWidgetDecoration,
          wrapperComponent: ToolbarProvider
        }),
        tauriWindowActor({
          defaultContext: {
            isDecorated: false,
            transparent: true
          },
          wrapperComponent: RootWindowWrapper,
          windowDecorationComponent: isDesktopLauncherWindow
            ? LauncherWindowWidgetDecoration
            : AppWindowWidgetDecoration
        }),
        widgetActor({
          widgetComponentsMap,
          dynamicWidgetComponentsMap,
          wrapperComponent: isDesktopLauncherWindow ? undefined : WidgetWrapper
        }),
        flexLayoutActor({
          wrapperComponent: FlexLayoutWrapper
        })
      ],
      uuidFn: UUID
    })
  );

  /**
   * Register the workspace as a service available to the React app
   */
  container.register(AppWorkspace, {
    useValue: workspace
  });

  /**
   * Handle workspace teardown
   */
  workspace.addHook('workspaceDestroy', () => {
    container.clearInstances();
    appLogger.log('Workspace Destroyed', workspace);
  });

  /**
   * Force load a widget on startup
   * This is useful for testing & running widgets in isolation/storybook
   */
  if (forceLoad) {
    workspace.addHook('leaderWindowReady', () => {
      switch (forceLoad.type) {
        case 'component': {
          Component.open(
            {
              ...forceLoad,
              componentProps: {
                ...forceLoad.componentProps,
                ...forceLoadProps
              }
            },
            PROCESS_ID.LEADER
          ).catch(console.error);
          break;
        }
        case 'form': {
          Form.open<AnyFormBuilder>(
            {
              ...forceLoad,

              form: {
                ...forceLoad.form,
                input: {
                  ...(forceLoad?.form?.input || {}),
                  ...forceLoadProps
                }
              }
            },
            PROCESS_ID.LEADER
          ).catch(console.error);
          break;
        }
        case 'layout': {
          if (forceLoadProps) {
            console.warn('Layouts do not accept props, ignoring forceLoadProps');
          }

          Layout.open(
            {
              ...forceLoad
            },
            PROCESS_ID.LEADER
          ).catch(console.error);
          break;
        }
        case 'dialog': {
          Dialog.open(
            {
              ...forceLoad,
              componentProps: {
                ...forceLoad.componentProps,
                ...forceLoadProps
              }
            },
            PROCESS_ID.LEADER
          ).catch(console.error);
          break;
        }
      }
    });
  }

  /**
   * Debugging hooks
   */
  workspace.addHook('windowReady', ({ isLeader }) => {
    appLogger.log(isLeader ? 'Leader Window Ready' : 'Window Ready', workspace);
  });

  /**
   * Create a loading renderer for the workspace on Tauri
   * to show a splash screen.
   */
  const loadingRenderer =
    isTauri() && appWindow.label === PROCESS_ID.LEADER
      ? () => {
          return <SplashScreenContainer />;
        }
      : undefined;

  function App() {
    return (
      <HelmetProvider>
        <ThemeProvider>
          <ReactWorkspace workspace={workspace} loadingRenderer={loadingRenderer} />
        </ThemeProvider>
      </HelmetProvider>
    );
  }

  return {
    App,
    workspace
  };
}
