import type { ReactWidgetActorWrapperComp } from '@valstro/workspace-react';
import { BROWSER_TAB_WINDOW_ACTOR_NAME } from '@valstro/workspace';
import { LauncherFrameContainer } from '@app/containers/launcher-frame/launcher-frame.container';
import type { ValstroEntitlement } from '@app/common/auth/keycloak.types';
import { useMemo } from 'react';
import { useCurrentAppWindow } from '@app/common/workspace/workspace.hooks';
import { AppStateWrapper } from './app-state.wrapper';

export const WidgetWrapper: ReactWidgetActorWrapperComp = ({ children }) => {
  const window = useCurrentAppWindow();
  const initMeta = window.initialDefinition.context.meta || {};
  const requiredRoles = (initMeta.requiredRoles as ValstroEntitlement[]) ?? [];
  const isDialog = initMeta.windowType === 'dialog';
  const isBrowserTab = window.name === BROWSER_TAB_WINDOW_ACTOR_NAME;

  if (isDialog) {
    return children;
  }

  const component = useMemo(
    () => <>{isBrowserTab ? <LauncherFrameContainer>{children}</LauncherFrameContainer> : children}</>,
    [children]
  );

  return <AppStateWrapper children={component} requiredRoles={requiredRoles} />;
};
