import { PROGRESS_RENDERER, CellBadgeClassEnum } from '@oms/frontend-vgrid';
import type { ColumnBuilderCallback, MarketDataBuilder } from '@oms/frontend-vgrid';
import {
  sharedLimitPriceCol,
  sharedSideCol,
  sharedOrderTypeCol,
  sharedAveragePriceCol,
  sharedSettleCurrencyCol,
  sharedInvestorAccountCol,
  sharedSettleTypeCol,
  sharedSettleDateCol,
  sharedOrderTifCol,
  sharedCreatedTimestampCol,
  sharedOrderStatusWithoutMapperCol,
  sharedUpdatedTimestampCol
} from '@app/common/grids/columns/order-cols';
import {
  sharedDefaultCol,
  sharedIdCol,
  sharedQuantityCol,
  sharedTextCol
} from '@app/common/grids/columns/generic-cols';
import { TradingOrderStatus, VisibilityReason } from '@oms/generated/frontend';
import { t } from '@oms/codegen/translations';
import { mapTradingOrderStatus } from '@app/common/mappers/map-trading-order-status';
import type { TradingOrderMonitorKeys, TradingOrderRow } from './trading-order-monitor.contracts';
import { mapOrderVisibilityReason } from '@app/common/mappers/map-order-visibility-reason';
import { TradingOrderCategory } from '@oms/generated/frontend';
import type { InstrumentMapping } from '@oms/generated/frontend';
import type { ColumnBuilder } from '@oms/frontend-vgrid';
import isEmpty from 'lodash/isEmpty';
import type { AnyRecord } from '@oms/frontend-foundation';
import type {
  ColDefMap,
  EnrichedColumnDef,
  TableServerColumnLibrary
} from '@app/common/grids/table-server/table-server.types';
import { buildColumnDefs } from '@app/common/grids/table-server/build-column-def';

export const defaultColumn: EnrichedColumnDef = {
  extends: 'generic.default',
  floatingFilter: true
};

const traidingOrderMonitorColumns: ColDefMap<TradingOrderMonitorKeys> = {
  id: { extends: 'orders.id' },
  instrumentDisplayCode: { extends: 'orders.instrumentDisplayCode' },
  instrumentLongName: { extends: 'orders.instrumentLongName' },
  instrument: { extends: 'orders.instrumentId' },
  instrumentCusip: { extends: 'orders.instrumentCusip' },
  instrumentExchCode: { extends: 'orders.instrumentExchCode' },
  side: { extends: 'orders.side' },
  locate: { extends: 'generic.text' },
  limitPrice: { extends: 'orders.price' },
  orderType: { extends: 'orders.orderType' },
  capacity: { extends: 'tradingOrders.tradingOrderCapacity' },
  totalQuantity: { extends: 'orders.quantity' },
  leavesQuantity: { extends: 'orders.quantity' },
  executedQuantity: { extends: 'orders.quantity', cellRenderer: 'ProgressRenderer' },
  settleCurrency: { extends: 'generic.currency', hide: true },
  settleType: { extends: 'orders.settleType', hide: true },
  settleDate: {
    extends: 'generic.date',
    minWidth: 160,
    hide: true
  },
  tradingAccountName: { extends: 'orders.investorAccount', floatingFilter: true },
  venue: { extends: 'generic.text' },
  timeInForce: { extends: 'orders.timeInForce' },
  expiryDateTime: {
    extends: 'generic.dateTime',
    hide: true
  },
  enteredByName: { extends: 'generic.text', width: 180, floatingFilter: true },
  status: {
    extends: 'orders.status',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: Object.values(TradingOrderStatus),
      valueFormatter: 'tradingOrderStatus'
    },
    valueFormatter: 'tradingOrderStatus',
    cellClass: CellBadgeClassEnum.Capital
  },
  averagePrice: { extends: 'orders.price' },
  createdTimestamp: { extends: 'generic.timestamp', sort: 'desc' },
  updatedTimestamp: { extends: 'generic.timestamp', sort: 'desc' },
  orderVisibilityReason: {
    width: 130,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: Object.values(VisibilityReason),
      valueFormatter: 'visibilityReason'
    },
    valueFormatter: 'visibilityReason',
    cellClass: CellBadgeClassEnum.Capital,
    hide: true
  },
  ownerName: {
    extends: 'generic.text',
    width: 180,
    floatingFilter: true
  },
  ownerID: {
    extends: 'orders.ownerId',
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    hide: true
  },
  orderTagIds: {
    width: 110,
    filter: false,
    sortable: false,
    hide: true
  },
  tradeCurrency: { extends: 'generic.currency', hide: true },
  customerNotes: { extends: 'generic.text', hide: true },
  representativeCode: { extends: 'generic.text', hide: true },
  targetExchangeId: { extends: 'generic.text' },
  category: {
    extends: 'orders.status',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: Object.values(TradingOrderCategory),
      valueFormatter: 'tradingOrderCategory'
    },
    valueFormatter: 'tradingOrderCategory',
    cellClass: CellBadgeClassEnum.Capital
  }
};

const tradingOrderMonitorColumnLibrary: TableServerColumnLibrary<TradingOrderMonitorKeys> = {
  defaultColumn,
  columns: traidingOrderMonitorColumns
};

export const buildTradingOrderMonitorColumnDefs = () =>
  buildColumnDefs(tradingOrderMonitorColumnLibrary, 'tradingOrderMonitor');

// ----------- GENERIC INSTRUMENT COLUMNS ----------------------------------------
// ----------- (placing here until generic / codegen is ready) -------------------

export type InstrumentField = Partial<
  | keyof InstrumentMapping
  | 'instrument'
  | 'instrumentCusip'
  | 'instrumentDisplayCode'
  | 'instrumentExchCode'
  | 'instrumentLongName'
>;

export type InstrumentFieldMap<T extends AnyRecord> = Partial<
  Record<InstrumentField, ColumnBuilderCallback<T>>
>;

export type InstrumentOverrideConfig<T extends AnyRecord> = InstrumentFieldMap<T> & {
  instrumentIdField?: string;
  instrumentDisplayCodeField?: string;
  instrumentLongNameField?: string;
  instrumentCusipField?: string;
  instrumentExchCodeField?: string;
  include?: InstrumentField[];
};

export const instrumentDisplayCode =
  (field: string) =>
  <T extends AnyRecord>(c: ColumnBuilder<T>) => {
    const builder = c
      .field(field)
      .header(t('app.common.symbol'))
      .width(110)
      .maxWidth(120)
      .minWidth(100)
      .filter('agTextColumnFilter');

    return builder;
  };

export const instrumentLongName =
  (field: string) =>
  <T extends AnyRecord>(c: ColumnBuilder<T>) =>
    c.field(field).header('Instrument Long Name').minWidth(100).hide();

export const cusip =
  (field: string) =>
  <T extends AnyRecord>(c: ColumnBuilder<T>) =>
    c.field(field).header('CUSIP').width(100).maxWidth(120).minWidth(90).hide();

export const instrumentId =
  (field: string) =>
  <T extends AnyRecord>(c: ColumnBuilder<T>) =>
    c.field(field).header('Instrument ID').width(100).maxWidth(120).minWidth(90).hide();

export const exchangeCode =
  (field: string) =>
  <T extends AnyRecord>(c: ColumnBuilder<T>) =>
    c.field(field).header('Exchange Code').width(200).maxWidth(200).minWidth(200).hide();

export const instrumentCols = <T extends AnyRecord = AnyRecord>(
  overrides: InstrumentOverrideConfig<T>
): ColumnBuilderCallback<T>[] => {
  const {
    instrumentIdField = 'instrument',
    instrumentDisplayCodeField = 'instrumentDisplayCode',
    instrumentExchCodeField = 'instrumentExchCode',
    instrumentCusipField = 'instrumentCusip',
    instrumentLongNameField = 'instrumentLongName',
    include
  } = overrides || {};
  const cols: InstrumentFieldMap<T> = {
    instrumentDisplayCode: instrumentDisplayCode(instrumentDisplayCodeField),
    instrumentLongName: instrumentLongName(instrumentLongNameField),
    instrumentCusip: cusip(instrumentCusipField),
    instrumentExchCode: exchangeCode(instrumentExchCodeField),
    instrument: instrumentId(instrumentIdField)
  };

  const overrideFn =
    (fn: ColumnBuilderCallback<T>, override: ColumnBuilderCallback<T>) => (cb: ColumnBuilder<T>) => {
      return override(fn(cb));
    };

  return Object.keys(cols)
    .filter((k) => {
      return isEmpty(include) || include?.includes(k as any);
    })
    .map((k) => {
      const builder: ColumnBuilderCallback<T> = cols[k as keyof typeof cols] as ColumnBuilderCallback<T>;
      if (!overrides) {
        return builder;
      }
      const overrideBuilder = overrides[k as keyof typeof overrides] as ColumnBuilderCallback<T> | undefined;
      return overrideBuilder ? overrideFn(builder, overrideBuilder) : builder;
    });
};

const defaultCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedDefaultCol<TradingOrderRow>(c).enablePivot().enableRowGroup();

const idCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedIdCol<TradingOrderRow>(c, 'id').hide();

const sideCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedSideCol<TradingOrderRow>(c).field('side');

const locateCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedTextCol(c, 'locate').header('Locate');

const limitPriceCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedLimitPriceCol(c);

const orderTypeCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedOrderTypeCol(c);

const totalQuantityCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedQuantityCol(c, 'totalQuantity').colId('quantity').header('Total Quantity').shortHeader('Total Qty');

const leavesQuantityCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedQuantityCol(c, 'leavesQuantity')
    .colId('leavesQuantity')
    .header('Leaves Quantity')
    .shortHeader('Leaves Qty');

const executedQuantityCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedQuantityCol(c, 'executedQuantity')
    .colId('executedQuantity')
    .header('Executed Quantity')
    .shortHeader('Executed Qty')
    .cell((c) => c.renderer(PROGRESS_RENDERER));

const averagePriceCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedAveragePriceCol<TradingOrderRow>(c, 'averagePrice');

const settleCurrencyCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedSettleCurrencyCol(c);

const venueCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedTextCol(c, 'venue').header(t('app.orders.orderMonitor.routeTo')).minWidth(90);

const investorAccountCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedInvestorAccountCol<TradingOrderRow>(c, 'tradingAccountName')
    .floatingFilter(true)
    .filter('agTextColumnFilter');

const settleTypeCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedSettleTypeCol(c);

const settleDateCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedSettleDateCol(c);

const timeInForceCol: ColumnBuilderCallback<TradingOrderRow> = (c) => sharedOrderTifCol(c);

const enteredByCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedTextCol(c, 'enteredByName')
    .header('Entered By')
    .width(180)
    .floatingFilter(true)
    .filter('agTextColumnFilter');

const statusCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedOrderStatusWithoutMapperCol(c)
    .filter('agSetColumnFilter')
    .filterParams<TradingOrderStatus>({
      values: Object.values(TradingOrderStatus),
      valueFormatter: ({ value }) => mapTradingOrderStatus(value)
    })
    .cell((c) => c.badge(CellBadgeClassEnum.Capital, (data) => mapTradingOrderStatus(data?.status)));

const updatedTimestampCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedUpdatedTimestampCol(c).sort('desc');

const createdTimestampCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  sharedCreatedTimestampCol(c).sort('desc');

const orderVisibilityReasonCol: ColumnBuilderCallback<TradingOrderRow> = (c) =>
  c
    .field('orderVisibilityReason')
    .header(t('app.orders.orderMonitor.visibilityReason'))
    .shortHeader(t('app.orders.orderMonitor.visibilityReason', { ns: 'short' }))
    .width(130)
    .filter('agSetColumnFilter')
    .filterParams<VisibilityReason>({
      values: Object.values(VisibilityReason),
      valueFormatter: ({ value }) => mapOrderVisibilityReason(value)
    })
    .hide()
    .cell((c) =>
      c.badge(CellBadgeClassEnum.Capital, (data) => mapOrderVisibilityReason(data?.orderVisibilityReason))
    );

export const tradingOrderMarketData = (m: MarketDataBuilder<TradingOrderRow & { ticker?: string }>) =>
  m.level1((l) =>
    l
      .defaultField((c) => c.minWidth(90).width(100).hide())
      .fields({
        tickerId: 'instrumentDisplayCode', // the ticker field to "join" with
        fields: [
          (c) => c.field('bidPrice').show().level1MarketData('bidPrice', 'ticker').filter(false),
          (c) => c.field('askPrice').show().level1MarketData('askPrice', 'ticker').filter(false)
        ]
      })
  );

export const investorOrderTradingOrderMonitorColumnLibrary = {
  defaultColumn: defaultCol,
  columns: [
    idCol,
    ...instrumentCols<TradingOrderRow>({
      displayCode: (cb) => cb.cell((c) => c.renderer('agGroupCellRenderer'))
    }).map((col) => col),
    sideCol,
    locateCol,
    limitPriceCol,
    orderTypeCol,
    totalQuantityCol,
    leavesQuantityCol,
    executedQuantityCol,
    settleCurrencyCol,
    settleTypeCol,
    settleDateCol,
    investorAccountCol,
    venueCol,
    timeInForceCol,
    enteredByCol,
    statusCol,
    averagePriceCol,
    createdTimestampCol,
    updatedTimestampCol,
    orderVisibilityReasonCol
  ]
};
