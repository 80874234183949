import { singleton } from 'tsyringe';
import { BroadcastSubject } from '@oms/shared-frontend/rx-broadcast';
import type { InstrumentTrackingEvent } from '@app/common/data-access/instrument-tracking.contracts';

const INSTRUMENT_TRACKING_CHANNEL_NAME = 'instrument-tracking';

/**
 * Used to broadcast the current selected instrument and to listen for changes.
 *
 * @usage
 * ```ts
 * const trackingSubject$ = container.resolve(InstrumentTrackingSubject);
 * const subscription = trackingSubject$.subscribe(({ instrumentId }) => {
 *  console.log('Instrument ID selected:', instrumentId);
 * });
 * ```
 */
@singleton()
export class InstrumentTrackingSubject extends BroadcastSubject<InstrumentTrackingEvent> {
  public readonly channelName: string;

  public constructor() {
    super(INSTRUMENT_TRACKING_CHANNEL_NAME);
    this.channelName = INSTRUMENT_TRACKING_CHANNEL_NAME;
  }
}
