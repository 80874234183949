import type { AnyRecord } from '@valstro/workspace';
import type { FieldContract, IAdvancedSelectField, ModifiedFieldForOptions } from '@oms/frontend-foundation';
import { ADVANCED_SELECT_QUERY_ENUM } from '@app/generated/common';
import type { AdditionalFormFieldUnion } from '@app/forms/form-builder/mappers/form.mappers';

export const createSymbolField = <TOutputContract extends AnyRecord, TFieldKey extends keyof TOutputContract>(
  fc: FieldContract<TOutputContract, AdditionalFormFieldUnion>,
  key: TFieldKey,
  overrides?: ModifiedFieldForOptions<IAdvancedSelectField>
) =>
  fc.field(key, 'advanced-select').advancedSelectOptions({
    label: 'Symbol',
    query: ADVANCED_SELECT_QUERY_ENUM.LOOKUP_INSTRUMENTS_SIMPLE_QUERY,
    autoSizeWidthToTrigger: false,
    filterStrategy: 'us-symbol',
    width: 400,
    inputProps: {
      style: {
        textTransform: 'uppercase'
      }
    },
    ...overrides
  });
