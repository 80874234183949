import { createSignal } from '@oms/shared-frontend/rx-broadcast';
import type { Signal } from '@oms/shared-frontend/rx-broadcast';
import type { AppDatabase } from '../offline/app-database';
import { filter } from 'rxjs';
import type { Observable } from 'rxjs';
import { inject, singleton } from 'tsyringe';
import { ProcessState } from './process-id.subject';

export type AppDataAccessStateNotReady = {
  isReady: false;
  isConnecting: boolean;
  db?: AppDatabase;
  error?: string | null;
};

export type AppDataAccessStateReady = {
  isReady: true;
  isConnecting: false;
  db: AppDatabase;
  error?: null;
};

export type AppDataAccessState = AppDataAccessStateNotReady | AppDataAccessStateReady;

export const DEFAULT_OFFLINE_DATA_ACCESS_STATE: AppDataAccessState = {
  isReady: false,
  isConnecting: false,
  error: undefined
};

/**
 * Signal for AppDatabase
 *
 * Used to keep the current state of offline database (not multi-process)
 * and to listen for changes to the AppDataAccessState
 *
 * Used primarily at the framework level to broadcast the current state of data access
 * @see plugin-offline-database
 *
 * @usage
 * ```ts
 * const offlineDatabaseSignal = container.resolve(AppDatabaseSignal);
 * const subscription = offlineDatabaseSignal.$.subscribe((state) => {
 *  console.log('App database state changed', state);
 * });
 * ```
 *
 * @usage
 * ```ts
 * constructor(@inject(AppDatabaseSignal) private offlineDatabaseSignal: AppDatabaseSignal) {
 *  const subscription = this.offlineDatabaseSignal.$.subscribe((state) => {
 *    console.log('App database state changed', state);
 *  });
 * }
 */
@singleton()
export class AppDatabaseSignal {
  public signal: Signal<AppDataAccessState>;

  constructor(@inject(ProcessState) private processState: ProcessState) {
    this.signal = createSignal<AppDataAccessState>(this.channelName, this.DEFAULT_STATE, {
      initialize$: this.processState.isLeaderProcess$,
      initializeOnce: false
    });
  }

  public get ready$() {
    return this.signal.$.pipe(filter((state) => state.isReady)) as Observable<AppDataAccessStateReady>;
  }

  public get channelName() {
    return 'appDatabase';
  }

  public get DEFAULT_STATE() {
    return DEFAULT_OFFLINE_DATA_ACCESS_STATE;
  }

  public reset() {
    this.signal.set(this.DEFAULT_STATE);
  }
}
