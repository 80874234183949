import {
  useVGrid,
  VGrid,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE
} from '@oms/frontend-vgrid';
import type { VisibleTradingOrderInfoFragment as TOFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { tradingOrderMonitorColumnLibrary } from './trading-order-monitor.columns';
import { TradingOrderMonitorDatasourceService } from './trading-order-monitor.datasource.service';
import { InvestorOrderMonitorVisibilityEventHander } from '../investor-order-monitor/grid-services/investor-order-monitor.visibility.event-handler';
import { useTradingOrderMonitorToolbarActions } from './toolbar-actions';
import { inlineTradingOrderActions } from './grid-actions/inline.trading-order.actions';
import { createOpenEntryTradingOrderAction } from './grid-actions/open-entry.trading-order.action';
import { cancelTradingOrderAction } from './grid-actions/cancel.trading-order.action';
import { forceCancelTradingOrderAction } from './grid-actions/force-cancel.trading-order.action';
import { modifyTradingOrderAction } from './grid-actions/modify.trading-order.action';
import { investorOrderMarketData } from '../investor-order-monitor/investor-order-monitor.columns';
import { viewTradingOrderAction } from './grid-actions/view.trading-order.action';
import { MarketDataEventHandler } from '@app/data-access/services/marketdata/event-handlers/market-data.event.handler';
import { OfflineGridEventHandler } from '@app/data-access/services/system/grids/offline-grid.event-handler';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import { createInstrumentSelectionEventHandler } from '@app/data-access/services/system/grids/instrument-selection.event-handler';
import { TOHasExecutedQuantity, TONoExecutedQuantity, TOPending } from '../utils/row-state-rule-utils';

export const TradingOrderMonitorWidgetComponent = () => {
  useTradingOrderMonitorToolbarActions();

  const datasourceService = useService(TradingOrderMonitorDatasourceService);
  const gridProps = useVGrid<TOFragment>(
    'trading-order-monitor',
    (builder) =>
      builder
        .columnLibrary(tradingOrderMonitorColumnLibrary)
        .rowSelection((c) => c.multiple())
        .datasource((d) => d.source(datasourceService.createServerSideDatasource()).rowId((r) => r.data.id))
        .marketData(investorOrderMarketData)
        .injectEvents([
          InvestorOrderMonitorVisibilityEventHander,
          MarketDataEventHandler,
          OfflineGridEventHandler,
          createInstrumentSelectionEventHandler<TOFragment>(
            'TRADING_ORDER_MONITOR',
            ({ instrument }) => instrument?.id
          )
        ])
        .rowStateRules({
          pending: (params) => TOPending(params.data),
          noExecutedQuantity: (params) => TONoExecutedQuantity(params.data),
          hasExecutedQuantity: (params) => TOHasExecutedQuantity(params.data)
        })
        .sideBar()
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(createOpenEntryTradingOrderAction<TOFragment>())
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(viewTradingOrderAction)
                .action(modifyTradingOrderAction)
                .action(cancelTradingOrderAction)
                .action(forceCancelTradingOrderAction)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(inlineTradingOrderActions)
                .action(refreshServersideCustomMenuAction)
            )
            .settings((s) => s.fromFile({}))
        ),

    [datasourceService]
  );

  return <VGrid {...gridProps} />;
};

export default TradingOrderMonitorWidgetComponent;
