import { BroadcastSubject, createBroadcastSignal } from '@oms/shared-frontend/rx-broadcast';
import type { Signal } from '@oms/shared-frontend/rx-broadcast';
import type { AuthClientState } from '@app/common/auth/keycloak.types';
import { DEFAULT_AUTH_STATE } from '@app/common/auth/auth.contracts';
import type { AuthEventAction } from '@app/common/auth/auth.contracts';
import { ProcessState } from './process-id.subject';
import { singleton, inject } from 'tsyringe';

/**
 * Signal for AuthClientState
 *
 * Used to broadcast the current state of the AuthClient
 * and to listen for changes to the AuthClientState
 *
 * Used primarily at the framework level to broadcast the current state of the AuthClient
 * @see app.stream.ts
 * @see plugin-auth
 *
 * @usage
 * ```ts
 * const authSignal = container.resolve(AuthSignal);
 * const subscription = authSignal.$.subscribe((state) => {
 *  console.log('Auth state changed', state);
 * });
 * ```
 *
 * @usage
 * ```ts
 * constructor(@inject(AuthSignal) private authSignal: AuthSignal) {
 *  const subscription = this.authSignal.$.subscribe((state) => {
 *    console.log('Auth state changed', state);
 *  });
 * }
 */
@singleton()
export class AuthSignal {
  public action$: BroadcastSubject<AuthEventAction>;
  public signal: Signal<AuthClientState>;

  constructor(@inject(ProcessState) private processState: ProcessState) {
    this.action$ = new BroadcastSubject<AuthEventAction>(`${this.channelName}-action`);
    this.signal = createBroadcastSignal<AuthClientState>(this.channelName, this.DEFAULT_STATE, {
      initialize$: this.processState.isLeaderProcess$,
      initializeOnce: false
    });
  }

  public get channelName() {
    return 'auth';
  }

  public get DEFAULT_STATE() {
    return DEFAULT_AUTH_STATE;
  }

  public reset() {
    this.signal.set(this.DEFAULT_STATE);
  }
}
