import type { ToolbarLocationKeys } from '@oms/frontend-vgrid';
import type { Action } from '../types/action.types';
import type { MouseEventHandler } from 'react';
import type { Sprinkles } from '@oms/shared-frontend/ui-design-system';
import type { ActionCommands } from '../commands/command.registry.types';

export type DragNDrop = {
  type: DragItemTypes;
  onDrag: () => void;
  onDrop: () => void;
  canDrop?: () => boolean;
};

export type OnCopyPaste = {
  onCopy: MouseEventHandler<HTMLElement>;
  onPaste: () => void;
};

export type OnDelete = {
  onDelete: MouseEventHandler<HTMLElement>;
};

export type DefinedColors = 'Blue' | 'DarkBlue' | 'LightBlue' | 'Red' | 'Secondary';

export const ACTION_COLOR_LABELS: Record<DefinedColors, Action['color']> = {
  Blue: {
    backgroundColor: '#0071F0',
    color: '#F2F8FF'
  },
  DarkBlue: {
    backgroundColor: '#00448F',
    color: '#F2F8FF'
  },
  LightBlue: {
    backgroundColor: '#7DB9FD',
    color: '#151C22'
  },
  Red: {
    backgroundColor: '#381313',
    color: '#F3AEAE'
  },
  Secondary: {
    backgroundColor: '#151C22',
    color: '#7DB9FD'
  }
};

export const ACTION_DEFINED_COLORS: Map<
  (typeof ACTION_COLOR_LABELS)['Blue']['backgroundColor'],
  DefinedColors
> = new Map(
  Object.entries(ACTION_COLOR_LABELS).map(([colorName, colorStyles]) => {
    return [colorStyles.backgroundColor, colorName as DefinedColors];
  })
);

export type LayoutAction = Omit<Action<ToolbarLocationKeys>, 'color'> & {
  color: DefinedColors;
};

export enum DragItemTypes {
  Separator = 'Separator',
  ActionGroup = 'ActionGroup',
  Action = 'Action'
}

export type DraggableSeparator = { isSeparator: true };

export type DraggableAction = Omit<Action, 'parentId' | 'order' | 'separator' | 'color' | 'commandId'> & {
  commandId?: ActionCommands;
  color: DefinedColors;
};
export type DraggableGroup = (DraggableAction | DraggableSeparator | DraggableGroup)[];

export type DraggableItemTypes = {
  DraggableSeparator: DraggableSeparator;
  DraggableAction: DraggableAction;
  DraggableGroup: DraggableGroup;
};

export type DraggableItem = DraggableSeparator | DraggableAction | DraggableGroup;

export type DragItemMeta = {
  opacity: number;
};

export type DropItemMeta = {
  borderStyle: Sprinkles['borderStyle'];
  borderWidth: Sprinkles['borderWidth'];
  borderColor: Sprinkles['borderColor'];
  border: Sprinkles['border'];
};
export type DragItemDropResult = {};
export type ActionDndContext = {
  action?: DraggableItem;
  type: DragItemTypes;
  objectPath: string;
  idx: number;
};
