import { Plugin } from '@valstro/workspace';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import {
  addOfflineDatabaseCollections,
  createOfflineDatabase,
  destroyAndClearDatabase
} from '@app/data-access/offline/offline-database';
import type { OfflineDatabase, OfflineDatabaseOptions } from '@app/data-access/offline/offline-database';
import { createLogger } from '@oms/shared/util';
import type { RxStorage } from 'rxdb-v15';
import { confirm as tauriConfirm } from '@tauri-apps/api/dialog';
import { relaunch, exit } from '@tauri-apps/api/process';
import { AppDatabaseSignal } from '@app/data-access/memory/app-database.signal';
import type { DependencyContainer } from 'tsyringe';
import { IS_TAURI } from '@app/common/workspace/workspace.constants';
import { AppDatabase } from '@app/data-access/offline/app-database';
import { MemoryDatabaseInitializerService } from '@app/data-access/offline/memory-database';
import type { MemoryDatabaseOptions } from '@app/data-access/offline/memory-database';

export const appDatabasePluginLogger = createLogger({ label: 'App Database Plugin' });

export interface AppDatabaseOptions {
  offline?: OfflineDatabaseOptions;
  memory?: MemoryDatabaseOptions;
  container: DependencyContainer;
}

/**
 * Creates the app database when the leader window is ready
 */
export const appDatabasePlugin = ({ container, memory = {}, offline = {} }: AppDatabaseOptions) =>
  Plugin.create<AppWorkspace>({
    name: 'valstro-app-database-plugin',
    pluginFn: ({ workspace }) => {
      const signalService = container.resolve(AppDatabaseSignal);
      const memoryDatabaseInitializer = container.resolve(MemoryDatabaseInitializerService);
      let appDatabase: AppDatabase | undefined;
      const unsubWindowReady = workspace.addHook('leaderElection', async ({ isLeader }) => {
        if (appDatabase) {
          signalService.signal.set({
            isConnecting: false,
            isReady: true,
            db: appDatabase,
            error: null
          });
          return;
        }

        try {
          signalService.signal.set({
            isConnecting: true,
            isReady: false,
            error: null
          });

          const existingOfflineDatabase = workspace.getMeta().offlineDatabase;
          const existingMemoryDatabase = workspace.getMeta().memoryDatabase;

          if (existingOfflineDatabase || existingMemoryDatabase) {
            return;
          }

          if (isLeader && memory && memory.storage === undefined) {
            memoryDatabaseInitializer.expose();
          }

          const { db: memoryDatabase } = await memoryDatabaseInitializer.create(isLeader, memory);
          const { db: offlineDatabase, storage: offlineStorage } = await createOfflineDatabase(offline);

          try {
            await addOfflineDatabaseCollections(offlineDatabase);
            await memoryDatabaseInitializer.addCollections();
          } catch (e) {
            console.error(e);
            signalService.signal.set({
              isConnecting: true,
              isReady: false,
              error: (e as Error)?.message
            });
            if (IS_TAURI) {
              await handleTauriRxDBError(offlineDatabase, offlineStorage);
            } else {
              await handleBrowserRxDBError(e, offlineDatabase, offlineStorage);
            }
          }

          appDatabase = new AppDatabase(memoryDatabase, offlineDatabase);
          workspace.updateMeta({
            offlineDatabase,
            memoryDatabase
          });
          const container = workspace.meta?.container;
          if (!container) {
            throw new Error('Container not found');
          }
          container.register(AppDatabase, { useValue: appDatabase });
          appDatabasePluginLogger.log('Created & registered offline & memory database', appDatabase);

          if (isLeader) {
            // Prune database on startup (remove orphaned grids and grid templates)
            pruneDatabase().catch(console.error);
          }

          signalService.signal.set({
            isConnecting: false,
            isReady: true,
            db: appDatabase,
            error: null
          });
        } catch (e) {
          console.error(e);
          signalService.signal.set({
            isConnecting: false,
            isReady: false,
            error: (e as Error)?.message
          });
        }
      });

      const unsubscribe = async () => {
        unsubWindowReady();
        await memoryDatabaseInitializer.destroy();
        appDatabasePluginLogger.log('Destroyed offline & memory database');
        signalService.reset();

        appDatabase = undefined;
      };

      return unsubscribe;
    }
  });

async function handleTauriRxDBError(offlineDatabase: OfflineDatabase, storage: RxStorage<any, any>) {
  const confirmed = await tauriConfirm(
    'Your local data is no longer compatible with the latest version of our application. Would you like to reset it?'
  );
  if (confirmed) {
    await destroyAndClearDatabase(offlineDatabase, storage);
    await relaunch();
  } else {
    await exit(1);
  }
}

async function handleBrowserRxDBError(
  e: unknown,
  offlineDatabase: OfflineDatabase,
  storage: RxStorage<any, any>
) {
  const confirmed = confirm(
    'Your local data is no longer compatible with the latest version of our application. Would you like to reset it?'
  );
  if (confirmed) {
    await destroyAndClearDatabase(offlineDatabase, storage);
    window.location.reload();
  } else {
    throw e;
  }
}

/**
 * Prunes the database by removing orphaned grids and grid templates
 */
async function pruneDatabase() {
  // Prune orphaned stuff here if need be
}
