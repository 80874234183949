import { MarketDataWidget } from '@app/data-access/services/marketdata/components/market-data.widget';
import { useMontageInstrumentDetails } from '@app/data-access/services/trading/montage/hooks/use-montage-instrument-details.hook';
import type { Sprinkles } from '@oms/shared-frontend/ui-design-system';
import { useMontageLayout } from './use-montage-layout.hooks';

const sx: Sprinkles = {
  backgroundColor: 'layout.level2',
  p: 'small',
  borderRadius: 'md'
};

export const MontageLevel1Banner = () => {
  const { layout } = useMontageLayout();

  const instrumentDetails = useMontageInstrumentDetails();

  if (!instrumentDetails) {
    return null;
  }

  return (
    <MarketDataWidget
      instrumentDetails={instrumentDetails}
      type={layout === 'compact' ? 'om-banner-compact' : 'om-banner-detailed'}
      sx={sx}
    />
  );
};
