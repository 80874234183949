import { useAppStateStream } from '@app/data-access/memory/app.stream';
import { Center, Flex } from '@oms/shared-frontend/ui-design-system';
import { APP_STATE_TYPE } from '@app/common/app/app.contracts';
import type { ValstroEntitlement } from '@app/common/auth/keycloak.types';
import { useMemo } from 'react';
import { useUserRoles } from '@app/data-access/services/system/auth/auth.hooks';

export const AppStateWrapper: React.FC<React.PropsWithChildren<{ requiredRoles?: ValstroEntitlement[] }>> = ({
  children,
  requiredRoles = []
}) => {
  const canAccess = useUserRoles();
  const isUserAuthorized = canAccess(requiredRoles);
  const { state } = useAppStateStream();

  const component = useMemo(
    () => (
      <Flex
        sx={{
          position: 'absolute',
          inset: 0
        }}
        direction="column"
      >
        {children}
      </Flex>
    ),
    [children]
  );

  switch (state) {
    case APP_STATE_TYPE.IDLE:
      return null;
    case APP_STATE_TYPE.AUTHENTICATING:
      return <Center>Authenticating...</Center>;
    case APP_STATE_TYPE.UNAUTHORIZED:
      return <Center>Unauthorized</Center>;
    case APP_STATE_TYPE.DATA_ACCESS_CONNECTING:
      return <Center>Connecting...</Center>;
    case APP_STATE_TYPE.DATA_ACCESS_DISCONNECTED:
      return <Center>Data connection lost. Please refresh or restart.</Center>;
    case APP_STATE_TYPE.SYNCRONISING:
      return <Center>Syncronising user configuration...</Center>;
    case APP_STATE_TYPE.READY:
      switch (true) {
        case !isUserAuthorized:
          return <Center>Unauthorized</Center>;
        default:
          return component;
      }
    default:
      return null;
  }
};
