import {
  useVGrid,
  VGrid,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE
} from '@oms/frontend-vgrid';
import type { VisibleInvestorOrderInfoWithAllocationsFragment as IOFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import { investorOrderMonitorColumnLibrary, investorOrderMarketData } from './investor-order-monitor.columns';
import { InvestorOrderMonitorDatasourceService } from './investor-order-monitor.datasource.service';
import { InvestorOrderMonitorVisibilityEventHander } from './grid-services/investor-order-monitor.visibility.event-handler';
import { useInvestorOrderMonitorToolbarActions } from './toolbar-actions';
import { acceptInvestorOrderAction } from './grid-actions/accept.investor-order.action';
import { cancelInvestorOrderAction } from './grid-actions/cancel.investor-order.action';
import { investorOrderDoneForDayAction } from './grid-actions/done-for-day.investor-order.action';
import { investorOrderGenerateOrderSummaryAction } from './grid-actions/generate-order-summary.investor-order.action';
import { inlineInvestorOrderActions } from './grid-actions/inline.investor-order.action';
import { rejectInvestorOrderAction } from './grid-actions/reject.investor-order.action';
import { viewInvestorOrderAction } from './grid-actions/view.investor-order.action';
import { investorOrderMasterDetail } from './investor-order-monitor.master-detail';
import { openIoEntryInvestorOrderAction } from './grid-actions/open-io-entry.investor-order.action';
import { openPrincipalFillAction } from './grid-actions/open-principal-fill.investor-order.action';
import { openCrossPrincipalFillAction } from './grid-actions/open-cross-principal-fill';
import { openRouteInvestorOrderAction } from './grid-actions/open-route.investor-order.action';
import { createOpenEntryTradingOrderAction } from '../trading-order-monitor/grid-actions/open-entry.trading-order.action';
import type { TypedInvestorOrderGridProps } from './investor-order-monitor.widget.config';
import { MarketDataEventHandler } from '@app/data-access/services/marketdata/event-handlers/market-data.event.handler';
import { OfflineGridEventHandler } from '@app/data-access/services/system/grids/offline-grid.event-handler';
import { refreshServersideCustomMenuAction } from '@app/common/grids/actions/refresh-serverside.action';
import type { TradingOrderDetailGridProps } from './investor-order-monitor.details.grid';
import { reactivateInvestorOrderAction } from './grid-actions/reactivate.investor-order.action';
import { useCurrentAppWidgetActorProps } from '@app/common/workspace/workspace.hooks';
import { createInstrumentSelectionEventHandler } from '@app/data-access/services/system/grids/instrument-selection.event-handler';
import { IOHasExecutedQuantity, IONoExecutedQuantity, IOPending } from '../utils/row-state-rule-utils';

export const InvestorOrderMonitorWidgetComponent = () => {
  const componentProps = useCurrentAppWidgetActorProps<TypedInvestorOrderGridProps>()[0];

  useInvestorOrderMonitorToolbarActions();

  const datasourceService = useService(InvestorOrderMonitorDatasourceService);
  const gridProps = useVGrid<IOFragment>(
    'investor-order-monitor',
    (builder) =>
      builder
        .columnLibrary(
          investorOrderMonitorColumnLibrary(componentProps?.nonfilterable, componentProps?.excluded)
        )
        .rowSelection((c) => c.multiple())
        .datasource((d) =>
          d
            .source(
              datasourceService.createServerSideDatasource({
                filterOverrides: componentProps?.filters
              })
            )
            .rowId((r) => r.data.id)
        )
        .marketData(investorOrderMarketData)
        .detailGrid<TradingOrderDetailGridProps>(investorOrderMasterDetail)
        .injectEvents([
          InvestorOrderMonitorVisibilityEventHander,
          MarketDataEventHandler,
          OfflineGridEventHandler,
          createInstrumentSelectionEventHandler<IOFragment>(
            'INVESTOR_ORDER_MONITOR',
            ({ instrument }) => instrument?.id
          )
        ])
        .rowStateRules({
          pending: (params) => IOPending(params.data),
          noExecutedQuantity: (params) => IONoExecutedQuantity(params.data),
          hasExecutedQuantity: (params) => IOHasExecutedQuantity(params.data)
        })
        .sideBar()
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(openIoEntryInvestorOrderAction('create'))
                .action(createOpenEntryTradingOrderAction<IOFragment>())
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(openCrossPrincipalFillAction)
                .action(openPrincipalFillAction)
                .action(investorOrderGenerateOrderSummaryAction)
                .action(investorOrderDoneForDayAction)
                .action(acceptInvestorOrderAction)
                .action(rejectInvestorOrderAction)
                .action(openRouteInvestorOrderAction)
                .action(viewInvestorOrderAction)
                .action(openIoEntryInvestorOrderAction('update'))
                .action(reactivateInvestorOrderAction)
                .action(cancelInvestorOrderAction)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
                .action(inlineInvestorOrderActions)
                .action(refreshServersideCustomMenuAction)
            )
            .settings((s) => s.fromFile({}))
        ),
    [datasourceService]
  );

  return <VGrid {...gridProps} />;
};

export default InvestorOrderMonitorWidgetComponent;
