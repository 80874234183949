import { Subject } from 'rxjs';
import { isTauri } from '@valstro/workspace';
import type { ComboBoxItem } from '@oms/frontend-foundation';
import { BroadcastSubject } from '@oms/shared-frontend/rx-broadcast';
import {
  initialCommandPaletteMemoryState as initialState,
  COMMAND_PALETTE
} from '@app/common/command-palette/command-palette.contracts';
import type {
  CommandPaletteCategory,
  CommandPaletteCategoryGroup,
  CommandPaletteItem,
  CommandPaletteOpenOptions,
  CommandPaletteMemoryState,
  CommandPaletteSubmitEvent
} from '@app/common/command-palette/command-palette.contracts';

/**
 * Command Palette Subjects that control the command palette (for the Command Palette Plugin)
 */
export const cmdPaletteOpen$ = isTauri()
  ? new BroadcastSubject<CommandPaletteOpenOptions>('cmd-palette-open')
  : new Subject<CommandPaletteOpenOptions>();

export const cmdPaletteUpdate$ = new BroadcastSubject<CommandPaletteOpenOptions>('cmd-palette-update');
export const cmdPaletteClose$ = new BroadcastSubject<ComboBoxItem[]>('cmd-palette-close');
export const cmdPaletteSelectedItemsChange$ = new BroadcastSubject<ComboBoxItem[]>(
  'cmd-palette-selected-items-change'
);
export const cmdPaletteSubmit$ = new BroadcastSubject<CommandPaletteSubmitEvent>('cmd-palette-submit');

/**
 * Command Palette Store
 * This is a simple in-memory store that is used to store state in the Leader process (in-memory).
 * We do this by exposing the store with remote-link to the other processes & using a proxy to
 * access the store in the other processes.
 *
 * @see CommandPaletteService
 */
export class CommandPaletteStore {
  public categories: Map<string, CommandPaletteCategory> = new Map();
  public categoryGroups: Map<string, CommandPaletteCategoryGroup> = new Map();
  public categoriesOrder: string[] = [];
  public items: Map<string, CommandPaletteItem> = new Map();
  // Note using `JSON.stringify` to save the command palette state instead of raw object
  // because nested arrays & objects cause too many updates when exposing the memory store with remote-link.
  // Note, should fix / improve this behavior in the future (@valstro/remote-link)
  // Or, move away from remote-link for this use case & use a different approach.
  public stringifiedState: string = JSON.stringify(initialState);

  constructor() {
    this.categories.set(COMMAND_PALETTE.CATEGORY_ALL, {
      isContextual: false,
      label: COMMAND_PALETTE.CATEGORY_ALL
    });
  }

  /**
   * Registers command palette items.
   *
   * @param items - the command palette items to register
   */
  public register(items: CommandPaletteItem[]) {
    items.forEach((item) => {
      this._registerItem(item);
    });

    this._setState();
  }

  /**
   * Unregisters command palette items.
   *
   * @param itemsOrIds - the command palette items or ids to unregister
   */
  public unregister(itemsOrIds: CommandPaletteItem[] | string[]) {
    itemsOrIds.forEach((itemOrId) => {
      // Note, this is NOT unsafe. eslint being dumb.

      this._unregisterItem(itemOrId);
    });

    this._setState();
  }

  /**
   * Unregisters all command palette items.
   */
  public unregisterAll() {
    this.items.clear();
    this.categories.clear();
    this.categoryGroups.clear();
    // re-add the "all" category
    this.categories.set(COMMAND_PALETTE.CATEGORY_ALL, {
      isContextual: false,
      label: COMMAND_PALETTE.CATEGORY_ALL
    });
    this._setState();
  }

  /**
   * Sets the order of the category groups inside a tab (category).
   *
   * @param order - the order of the category groups
   */
  public setCategoriesOrder(order: string[]) {
    this.categoriesOrder = order;
  }

  /**
   * Registers a command palette item.
   *
   * @param item - the command palette item to register
   */
  private _registerItem(item: CommandPaletteItem) {
    const group = item.group || COMMAND_PALETTE.CATEGORY_GROUP_UNKNOWN;
    const category = item.category || COMMAND_PALETTE.CATEGORY_UNKNOWN;

    this.items.set(item.id, {
      ...item,
      category,
      group
    });

    this.categories.set(category, {
      isContextual: !!item.isContextual,
      label: category
    });

    this.categoryGroups.set(`${category}-${group}`, {
      category,
      label: group
    });
  }

  /**
   * Unregisters a command palette item.
   *
   * @param itemOrId - the command palette item or id to unregister
   */
  private _unregisterItem(itemOrId: CommandPaletteItem | string) {
    const item = typeof itemOrId === 'string' ? this.items.get(itemOrId) : itemOrId;
    if (!item) {
      return;
    }

    const group = item.group || COMMAND_PALETTE.CATEGORY_GROUP_UNKNOWN;
    const category = item.category || COMMAND_PALETTE.CATEGORY_UNKNOWN;

    this.items.delete(item.id);

    // if none left in the group, remove the group
    const itemsInGroup = Array.from(this.items.values()).filter(
      (item) => item.category === category && item.group === group
    );

    if (!itemsInGroup.length) {
      this.categoryGroups.delete(`${category}-${group}`);
    }

    // if none left in the category, remove the category
    const itemsInCategory = Array.from(this.items.values()).filter((item) => item.category === category);
    if (!itemsInCategory.length) {
      this.categories.delete(category);
    }
  }

  /**
   * Sets the command palette state.
   */
  private _setState() {
    const state: CommandPaletteMemoryState = {
      categories: Array.from(this.categories.values()),
      categoryGroups: Array.from(this.categoryGroups.values()),
      items: Array.from(this.items.values()),
      categoriesOrder: [...this.categoriesOrder]
    } as CommandPaletteMemoryState;

    // Bundling the command palette state into a single string to avoid too many updates
    // when exposing the memory store with remote-link. See note above.
    this.stringifiedState = JSON.stringify(state);
  }
}
