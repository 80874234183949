import { BroadcastSubject, createBroadcastSignal } from '@oms/shared-frontend/rx-broadcast';
import type { Signal } from '@oms/shared-frontend/rx-broadcast';
import { ProcessState } from './process-id.subject';
import { singleton, inject } from 'tsyringe';

/**
 * Signal for SyncronisationState
 *
 * Used to broadcast the current state of UI states replication
 * and to listen for changes to UI states replication
 *
 * Used primarily at the framework level to broadcast the current state of the AuthClient
 * @see app.stream.ts
 * @see plugin-syncronisation
 *
 * @usage
 * ```ts
 * const syncronisationSignal = container.resolve(SyncronisationSignal);
 * const subscription = syncronisationSignal.$.subscribe((state) => {
 *  console.log('Syncronisation state changed', state);
 * });
 * ```
 *
 * @usage
 * ```ts
 * constructor(@inject(SyncronisationSignal) private syncronisationSignal: SyncronisationSignal) {
 *  const subscription = this.syncronisationSignal.$.subscribe((state) => {
 *    console.log('Syncronisation state changed', state);
 *  });
 * }
 */

export const DEFAULT_SYNCRONISATION_STATE: SyncronisationState = {
  isSyncronising: false,
  isSyncronised: false,
  isSyncronisationError: false,
  syncronisationError: null
};

export type SyncronisationState = {
  isSyncronising: boolean;
  isSyncronised: boolean;
  isSyncronisationError: boolean;
  syncronisationError: string | null;
};
export const SYNCRONISATION_ACTION = {
  START: 'start',
  STOP: 'stop'
} as const;

export type SyncronisationAction = (typeof SYNCRONISATION_ACTION)[keyof typeof SYNCRONISATION_ACTION];

@singleton()
export class SyncronisationSignal {
  public action$: BroadcastSubject<SyncronisationAction>;
  public signal: Signal<SyncronisationState>;

  constructor(@inject(ProcessState) private processState: ProcessState) {
    this.action$ = new BroadcastSubject<SyncronisationAction>(`${this.channelName}-action`);
    this.signal = createBroadcastSignal<SyncronisationState>(this.channelName, this.DEFAULT_STATE, {
      initialize$: this.processState.isLeaderProcess$,
      initializeOnce: false
    });
  }

  public get channelName() {
    return 'syncronisation';
  }

  public get DEFAULT_STATE() {
    return DEFAULT_SYNCRONISATION_STATE;
  }

  public reset() {
    this.signal.set(this.DEFAULT_STATE);
  }
}
