import { Plugin } from '@valstro/workspace';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import { Logger } from '@oms/shared/util';
import { container } from 'tsyringe';
import { InstrumentTrackingSubject } from '@app/data-access/memory/instrument-tracking.subject';

const logger = Logger.labeled('InstrumentTrackingEvent');

/**
 * TODO: This is temp! ... remove this plugin after feature development is complete!
 * (or repurpose it)
 * For now this is only use to create a single sub to the event for dev logging.
 * I see it as useful for development, but can be removed later.
 */
export const InstrumentTrackingPlugin = () =>
  Plugin.create<AppWorkspace>({
    name: 'instrument-tracking-plugin',
    pluginFn: () => {
      const trackingSubject$ = container.resolve(InstrumentTrackingSubject);

      const subscription = trackingSubject$.subscribe((event) => {
        logger.debug(`${event.instrumentId} selected from ${event.sourceType}`, event);
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  });
