import {
  useVGrid,
  VGrid,
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  ROW_SELECTION_COUNT_ACTION_TYPE
} from '@oms/frontend-vgrid';
import type { GridBuilder } from '@oms/frontend-vgrid';
import { useService } from '@oms/frontend-foundation';
import { InvestorOrderTradingOrdersService } from '@app/data-access/services/trading/investor-orders/investor-order-trading-orders.service';
import type { InvestorOrderTradingOrderRow } from '@app/data-access/services/trading/investor-orders/investor-order-trading-orders.service';
import { useCallback } from 'react';
import { investorOrderTradingOrdersColumnLibrary } from './investor-order-view.trading-orders.columns';
import { OfflineGridEventHandler } from '@app/data-access/services/system/grids/offline-grid.event-handler';
import { viewTradingOrderAction } from '../trading-order-monitor/grid-actions/view.trading-order.action';
import { modifyTradingOrderAction } from '../trading-order-monitor/grid-actions/modify.trading-order.action';
import { cancelTradingOrderAction } from '../trading-order-monitor/grid-actions/cancel.trading-order.action';
import { TOHasExecutedQuantity, TONoExecutedQuantity, TOPending } from '../utils/row-state-rule-utils';

export const InvestorOrderTradingOrdersGrid = ({ id }: { id: string }) => {
  const ioTradingOrdersService = useService(InvestorOrderTradingOrdersService);

  const builder = useCallback(
    (b: GridBuilder<InvestorOrderTradingOrderRow>) =>
      b
        .columnLibrary(investorOrderTradingOrdersColumnLibrary)
        .sideBar()
        .datasource((d) => d.source(ioTradingOrdersService.watchAll$(id)).rowId((r) => r.data.id))
        .injectEvents([OfflineGridEventHandler])
        .rowStateRules({
          pending: (params) => TOPending(params.data),
          noExecutedQuantity: (params) => TONoExecutedQuantity(params.data),
          hasExecutedQuantity: (params) => TOHasExecutedQuantity(params.data)
        })
        .rowSelection((c) => c.multiple())
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(viewTradingOrderAction)
                .action(modifyTradingOrderAction)
                .action(cancelTradingOrderAction)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
            )
            .settings((s) => s.fromFile({}))
        ),
    [ioTradingOrdersService, id]
  );

  const props = useVGrid<InvestorOrderTradingOrderRow>('investor-order-trading-orders', builder, [
    ioTradingOrdersService
  ]);
  return <VGrid {...props} />;
};

export default InvestorOrderTradingOrdersGrid;
