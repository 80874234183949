import { Lifecycle, scoped, inject } from 'tsyringe';
import type { EventHandler, EventSource, GridEvent } from '@oms/frontend-vgrid';
import { Logger, Result } from '@oms/shared/util';
import { InstrumentTrackingSubject } from '@app/data-access/memory/instrument-tracking.subject';
import {
  InstrumentIdResolver,
  TrackingSourceType
} from '@app/common/data-access/instrument-tracking.contracts';
import { AnyRecord } from '@oms/frontend-foundation';

const logger = Logger.labeled('InstrumentSelectionEventHandler');

export const INSTRUMENT_SELECTION_HANDLER_NAME = 'instrument-selection';

/**
 * Creates event handler for instrument selection
 * - Fire-and-forget events sent to `InstrumentTrackingSubject`
 * - Used by any component that tracks the currently selected instrument
 */
export const createInstrumentSelectionEventHandler = <TData extends AnyRecord>(
  sourceType: TrackingSourceType,
  resolveInstrumentId: InstrumentIdResolver<TData>
) => {
  @scoped(Lifecycle.ContainerScoped)
  class InstrumentSelectionEventHandler implements EventHandler<TData> {
    public name = `${INSTRUMENT_SELECTION_HANDLER_NAME}-${sourceType}`;
    public sourceType: TrackingSourceType;

    // 🏗️ Constructor ---------------------------------------------------- /

    constructor(@inject(InstrumentTrackingSubject) protected trackingSubject$: InstrumentTrackingSubject) {
      this.sourceType = sourceType;
    }

    // 📢 Public ---------------------------------------------------- /

    public addEvents(eventSource: EventSource<keyof GridEvent, TData>): void {
      eventSource.add('onRowClicked', (event) => {
        this.resolveInstrumentId(event.data).mapSync(
          (instrumentId) => {
            // Don't send the event on deselect
            if (event.api.getSelectedRows().length) {
              this.trackingSubject$.next({
                sourceId: event.node.id,
                sourceType: this.sourceType,
                instrumentId
              });
            }
          },
          (reason) => {
            logger.warn(`${reason}:`, event);
          }
        );
      });
    }

    public removeEvents(): void {}

    // 🔒 Protected / Private ---------------------------------------------- /

    protected resolveInstrumentId(data?: TData): Result<string, string> {
      if (!data) {
        return Result.failure('Data does not exist for the selected row');
      }
      const instrumentId = resolveInstrumentId(data);
      if (instrumentId) {
        return Result.success(instrumentId);
      } else {
        return Result.failure('No instrument ID was found for row the selected row');
      }
    }
  }
  return InstrumentSelectionEventHandler;
};
