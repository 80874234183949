import { useCallback } from 'react';
import { TradingOrderInvestorOrdersService } from '@app/data-access/services/trading/trading-orders/trading-order-investor-orders.service';
import type { TradingOrderInvestorOrderRow } from '@app/data-access/services/trading/trading-orders/trading-order-investor-orders.service';
import type { VisibleTradingOrderInfoWithAllocationsFragment } from '@oms/generated/frontend';
import { useService } from '@oms/frontend-foundation';
import {
  CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE,
  ROW_SELECTION_COUNT_ACTION_TYPE,
  useVGrid,
  VGrid
} from '@oms/frontend-vgrid';
import type { GridBuilder } from '@oms/frontend-vgrid';
import { tradingOrderInvestorOrdersColumnLibrary } from './trading-order-view.investor-orders.columns';
import { OfflineGridEventHandler } from '@app/data-access/services/system/grids/offline-grid.event-handler';
import { viewInvestorOrderAction } from '../investor-order-monitor/grid-actions/view.investor-order.action';
import { openIoEntryInvestorOrderAction } from '../investor-order-monitor/grid-actions/open-io-entry.investor-order.action';
import { cancelInvestorOrderAction } from '../investor-order-monitor/grid-actions/cancel.investor-order.action';
import { IOHasExecutedQuantity, IONoExecutedQuantity, IOPending } from '../utils/row-state-rule-utils';

export const TradingOrderInvestorOrdersGrid = ({
  id
}: {
  id: VisibleTradingOrderInfoWithAllocationsFragment['id'];
}) => {
  const toInvestorOrdersService = useService(TradingOrderInvestorOrdersService);

  const builder = useCallback(
    (b: GridBuilder<TradingOrderInvestorOrderRow>) =>
      b
        .columnLibrary(tradingOrderInvestorOrdersColumnLibrary)
        .datasource((d) => d.source(toInvestorOrdersService.watchAll$(id)).rowId((r) => r.data.id))
        .injectEvents([OfflineGridEventHandler])
        .rowStateRules({
          pending: (params) => IOPending(params.data),
          noExecutedQuantity: (params) => IONoExecutedQuantity(params.data),
          hasExecutedQuantity: (params) => IOHasExecutedQuantity(params.data)
        })
        .sideBar()
        .rowSelection((c) => c.multiple())
        .actions((a) =>
          a
            .schema((s) =>
              s
                .action(CUSTOM_MENU_CLEAR_GRID_FILTER_ACTION_TYPE)
                .action(viewInvestorOrderAction)
                .action(openIoEntryInvestorOrderAction('update'))
                .action(cancelInvestorOrderAction)
                .action(ROW_SELECTION_COUNT_ACTION_TYPE)
            )
            .settings((s) => s.fromFile({}))
        ),
    [toInvestorOrdersService, id]
  );

  const props = useVGrid('trading-order-investor-orders', builder, [toInvestorOrdersService]);
  return <VGrid {...props} />;
};

export default TradingOrderInvestorOrdersGrid;
