import { Plugin } from '@valstro/workspace';
import type { AppWorkspace } from '@app/app-config/workspace.config';
import type { DependencyContainer } from 'tsyringe';
import { RxdbReplicationService } from '@app/data-access/offline/rxdb-replication.service';
import { createWorkspaceLeaderWindowReady$ } from '@app/common/workspace/workspace.rxjs';
import { filter, switchMap } from 'rxjs';
import { AppState } from '@app/data-access/memory/app.stream';

export interface SyncronisationPluginOptions {
  container: DependencyContainer;
}

export const syncronisationPlugin = ({ container }: SyncronisationPluginOptions) => {
  return Plugin.create<AppWorkspace>({
    name: 'valstro-syncronisation-plugin',
    pluginFn: ({ workspace }) => {
      const leaderWindowReady$ = createWorkspaceLeaderWindowReady$(workspace);
      const appState = container.resolve(AppState);
      let replication: RxdbReplicationService | undefined;
      const subscription = leaderWindowReady$
        .pipe(switchMap(() => appState.$.pipe(filter((state) => state.state === 'Connected'))))
        .subscribe(() => {
          replication = container.resolve(RxdbReplicationService);
          replication.init().catch((error) => {
            console.error('Error initializing replication', error);
          });
          workspace.updateMeta({
            replication
          });
        });
      return () => {
        subscription.unsubscribe();
        if (replication) {
          replication.stopReplication();
        }
      };
    }
  });
};
